import React, {useContext, useState} from "react";
import {
	Card,
	Steps,
	Typography,
	Button,
	Row,
	Col,
	ConfigProvider,
	Image,
	Modal,
	Upload,
	message,
	Input,
	Tag, Space
} from "antd";
import {DownloadOutlined, ZoomOutOutlined, ZoomInOutlined, UploadOutlined, PaperClipOutlined} from "@ant-design/icons";
import style from "./styles/bill.module.css";
import "./styles/style.css";
import api from "@modules/api";
import UserContext from "@context/UserContext";
import dayjs from "dayjs";
import {numberFormat, formatDate} from "@modules/common";

const {Title} = Typography;

export const Bill = ({bill, forPayment = false, removeBill, showAuthor = false, toggleLoading}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [paymentCard, setPaymentCard] = useState(null);
	const [typeBtn, setTypeBtn] = useState(null);
	const [commitEnd, setCommitEnd] = useState(false);
	const userSelf = useContext(UserContext);

	const toggleIsOpen = () => setIsOpen((prev) => !prev);

	const changeStatusBill = async (type) => {
		toggleLoading();
		await api("bill/change_status", {
			method: "post",
			body: {
				type,
				paymentCard,
				billId: bill.id,
				...(commitEnd ? {commitEnd} : null),
			},
		});
		removeBill(bill.id);
		toggleLoading();
	};

	const Buttons = () => {
		const eventBtn = async (type) => {
			const checkOrganization = bill.organizationType === 'organization' && bill.organizationId === 2;
			if ((checkForPayment() && checkOrganization) || type === 'cancel') {
				setTypeBtn(type);
				setCommitEnd(null);
				toggleIsOpen();
			} else {
				await changeStatusBill(type);
			}
		};

    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          justifyContent: "flex-end",
          gap: "12px",
          alignItems: "center",
        }}
      >
        <Button danger onClick={() => eventBtn("cancel")}>
          {forPayment && checkForPayment() ? "Отклонено" : "Отменить"}
        </Button>
        <ConfigProvider
          theme={{
            components: {
              Button: {
                colorPrimary: "#35C14F",
                defaultBorderColor: "#35C14F",
                colorText: "#35C14F",
                algorithm: true,
              },
            },
          }}
        >
          <Button color="primary" onClick={() => eventBtn("confirm")}>
            {forPayment && checkForPayment() ? "Оплачено" : "Согласовать"}
          </Button>
        </ConfigProvider>
      </div>
    );
  };

	const NormalizeItems = () => {
		return bill.confirmList.map((value, i) => {
			const item = {};
			const {status, user} = value;
			const {f, o} = user;
			switch (status) {
				case "confirm":
					item.status = "finish";
					item.title = (
						<Typography.Paragraph ellipsis={{rows: 2}} className={style.StepTitle}>
							{bill.confirmList.length - 1 === i ? "Оплачен" : "Согласован"} <br/>
							<div style={{fontSize: "15px", marginTop: "3px"}}>{formatDate(value.eventDate)}</div>
						</Typography.Paragraph>
					);
					break;
				case "new":
					item.status = "process";
					item.title = (
						<Typography.Paragraph ellipsis={{rows: 2}} className={style.StepTitle}>
							{forPayment ? "В процессе" : i === bill.confirmList.length - 1 ? "Ожидание" : "На согласовании"}
						</Typography.Paragraph>
					);
					break;
				case "cancel":
					item.status = "error";
					item.title = (
						<>
							<Typography.Paragraph ellipsis={{rows: 3}} className={style.StepTitle}>
								Отклонен <br/>
								<div style={{fontSize: "15px", marginTop: "3px"}}>{formatDate(value.eventDate)}</div>
							</Typography.Paragraph>
						</>
					);
					break;
				default:
					item.status = "wait";
					item.title = (
						<Typography.Paragraph ellipsis={{rows: 2}} className={style.StepTitle}>
							Ждет
						</Typography.Paragraph>
					);
					break;
			}
			item.description = <Typography.Paragraph ellipsis={{rows: 3}}
																							 className={style.StepDescription}>{`${f} ${user.i} ${o}`}</Typography.Paragraph>;

			return item;
		});
	};

	const objectList = [
		{
			key: "expenses",
			title: "Статья расходов",
		},
		{
			key: "howToPay",
			title: "За что платим",
		},
		{
			key: "sum",
			title: "Сумма счета",
		},
		{
			key: "firm",
			title: "Фирма",
		},
		{
			key: "organization",
			title: "Филиал",
		},
		{
			key: "comment",
			title: "Комментарий",
		},
		{
			key: "priority",
			title: "Приоритет",
		},
		{
			key: "billFile",
			title: "Копия счета",
		},
		...(showAuthor
			? [
				{
					key: "author",
					title: "Автор",
				},
			]
			: []),
		...(bill.paymentCard?.length
			? [
				{
					key: "paymentCard",
					title: "Платежка",
				},
			]
			: []),
		...(bill.status === "deviations"
			? [
				{
					key: "commentEnd",
					title: "Причина отклонения",
				},
			]
			: []),
	];

	const filesComponent = (files, key) => (
		<div style={{display: "flex", gap: "5px", flexWrap: "wrap"}} key={key}>
			{files.map(({name, id, url}) => {
				let fileRender =
					url.toLowerCase().endsWith(".pdf")
						? {
							imageRender: () => {
								return <iframe src={url} style={{width: "calc(100vw)", height: "100%"}}/>;
							},
						}
						: {
							toolbarRender: (_, {transform: {scale}, actions: {onZoomOut, onZoomIn}}) => (
								<div style={{display: "flex", alignItems: "center", gap: "15px"}}>
									<a style={{color: "white"}} download href={"/" + url}>
										<Button icon={<DownloadOutlined/>}/>
									</a>
									<Button icon={<ZoomOutOutlined/>} disabled={scale === 1} onClick={onZoomOut}/>
									<Button icon={<ZoomInOutlined/>} disabled={scale === 50} onClick={onZoomIn}/>
								</div>
							),
						};
				return <FileComponent name={name} fileRender={fileRender} url={url} key={url}/>
			})}
		</div>
	);

	const FileComponent = ({name, fileRender, url}) => {
		const [open, setOpen] = useState(false);
		const fileName = () => {
			const index = name.indexOf("-");
			return name.slice(index + 1);
		};
		return (
			<>
				<div style={{display: "flex", alignItems: "center", gap: "5px", cursor: "pointer", color: "#0486FF"}}>
					<a href={url} onClick={e => {
						e.preventDefault();
						e.stopPropagation();
						setOpen(true)
					}}><PaperClipOutlined/>{fileName()}</a>
				</div>
				{open ? (
					<Image
						preview={{
							visible: open,
							destroyOnClose: true,
							onVisibleChange: (value) => {
								setOpen(value);
							},
							toolbarRender: () => null,
							...fileRender,
						}}
						key={url}
						src={url.endsWith(".pdf") || url.endsWith(".xls") || url.endsWith(".xlsx") ? "/pdf.svg" : url}
						style={{objectFit: "contain", display: "none"}}
					/>
				) : (
					""
				)}
			</>
		);
	};

  const getValueBill = (key) => {
    switch (key) {
      case "expenses":
        return `${bill.expenses.number} ${bill.expenses.name}`;
      case "howToPay":
        return bill.howToPay;
      case "billFile":
        return filesComponent(bill.files);
      case "paymentCard":
        return filesComponent(bill.paymentCard || []);
      case "firm":
        return bill.firm.name;
      case "sum":
        return `${numberFormat(bill.sum)} RUB`;
      case "organization":
        return bill.organization.title ? bill.organization.title : bill.organization.name;
      case "comment":
        return bill.comment;
      case "commentEnd":
        return bill.commentEnd;
      case "author":
        return (
          <div style={{display: "flex", alignItems: "center", gap: "5px", flexWrap: "wrap"}}>
            {bill.author.f} {bill.author.i} {bill.author.o || ""} {bill.author.email ? <div style={{fontSize: 14}}>({bill.author.email})</div> : ""}
          </div>
        );

      default:
        return translatePriority(bill.priority);
    }
  };

	const translatePriority = (priority) => {
		switch (priority) {
			case "low":
				return "Низкий";
			case "medium":
				return "Средний";
			default:
				return "Высокий";
		}
	};

	const checkForPayment = () => {
		return !!bill.billConfirmations[bill.billConfirmations.length - 1].status;
	};

	const beforeUpload = (file) => {
		const mimeType = ["image/jpeg", "image/png", "application/pdf"];
		const isIMG = mimeType.some((type) => file.type === type);
		if (!isIMG) {
			message.error(`${file.name} не поддерживаемый формат файла`, 5);
		}
		return isIMG || Upload.LIST_IGNORE;
	};

	const handleChange = (info) => {
		info.fileList.forEach((file) => {
			if (file.response) {
				setPaymentCard(file.response.file.filename);
				file.url = `api/upload/${file.response.file.filename}`;
			}
		});
	};

	const getBage = () => {
		const {status} = bill;
		switch (status) {
			case "deviations":
				return (
					<Tag color="red" style={{fontSize: 14, padding: "3px 7px"}}>
						Отклонён
					</Tag>
				);
			case "paid":
				return (
					<Tag color="green" style={{fontSize: 14, padding: "3px 7px"}}>
						Оплачен
					</Tag>
				);
			case "awaitingPayment":
				return (
					<Tag color="orange" style={{fontSize: 14, padding: "3px 7px"}}>
						На рассмотрении
					</Tag>
				);
			case "onСonfirmation":
				return (
					<Tag color="orange" style={{fontSize: 14, padding: "3px 7px"}}>
						На рассмотрении
					</Tag>
				);
			default:
				return "";
		}
	};

  return (
    <Card size={"small"} style={{marginBottom: 20}} styles={{body: {padding: "20px 20px"}}}>
      <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <Typography className={style.CardTitle}>
          Согласование № {bill.id} от {dayjs(bill.createdAt).format("DD.MM.YY HH:mm")}
        </Typography>
        {getBage()}
      </div>
      <div style={{display: "flex", justifyContent: "flex-start", gap: "70px", margin: "30px 0 10px 0"}}>
        <div>
          <Steps style={{width: "180px"}} items={NormalizeItems()} direction="vertical" labelPlacement="horizontal" size="default" />
        </div>
        <div style={{display: "grid", gap: "12px 35px", gridTemplateColumns: "170px 1fr"}}>
          <>
            {objectList.map((obj) => (
              <>
                <Title level={5} style={{margin: 0}}>
                  {obj.title}:{" "}
                </Title>
                <div>{getValueBill(obj.key)}</div>
              </>
            ))}
          </>
        </div>
      </div>
      {forPayment && bill.billConfirmations.findLast((val) => val.status === "new")?.user.id === userSelf.id ? <Buttons /> : ""}
      <Modal
        open={isOpen}
        width={1000}
        title={typeBtn === "confirm" ? "Добавление платежки" : "Отклонение счета"}
        okText={typeBtn === "confirm" ? "Оплачено" : "Отклонено"}
        cancelText={"Закрыть"}
        onCancel={toggleIsOpen}
        onOk={() => {
          toggleIsOpen();
          changeStatusBill(typeBtn);
        }}
        okButtonProps={{
          disabled: typeBtn === "confirm" ? false : !commitEnd,
          style: {
            backgroundColor: typeBtn === "confirm" ? "rgba(23, 146, 23, 0.87)" : "rgba(219, 29, 29, 0.87)",
            color: "white",
          },
        }}
        closable={false}
      >
        <Row style={{alignItems: "center"}}>
          <Col span={4}>{typeBtn === "confirm" ? "Платежка" : "Причина отклонения"}:</Col>
          <Col span={19} offset={1}>
            {typeBtn === "confirm" ? (
              <Upload
                action="api/upload"
                onRemove={() => setPaymentCard(null)}
                listType="picture"
                beforeUpload={beforeUpload}
                onChange={handleChange}
              >
                {!paymentCard && (
                  <Button type="primary" icon={<UploadOutlined />}>
                    Добавить
                  </Button>
                )}
              </Upload>
            ) : (
              <Input placeholder={"Укажите причину отклонения"} value={commitEnd} onChange={(e) => setCommitEnd(e.target.value)} />
            )}
          </Col>
        </Row>
      </Modal>{" "}
    </Card>
  );
};
