import {useContext, useState, useEffect} from "react";
import {Link, Route, Routes, useLocation} from "react-router-dom";
import {Layout, Menu, theme, Spin, Typography, Drawer, Button, Row, Col, Switch, Space} from "antd";
import UserContext from "@context/UserContext.js";
import getNavigate from "../nav";
import getRoutes from "../routes";
import "./style.css";
import api from "@modules/api";
import {UserOutlined} from "@ant-design/icons";

const {Content, Sider} = Layout;

const {Title} = Typography;

const DefaultLayout = ({logout, setUser}) => {
	const [isLoading, setIsLoading] = useState(true);
	const [isOpenDrawer, setIsOpenDrawer] = useState(false);
	const [isLoadingChecked, setIsLoadingChecked] = useState({
		notificationMyBill: false,
		notificationBillConfirm: false
	});
	const user = useContext(UserContext);
	const location = useLocation();
	const {pathname} = location;
	const {
		token: {colorBgContainer, borderRadiusLG},
	} = theme.useToken();

	const setLinks = (items) => {
		return items?.length
			? items.map((el) => {
				let children;
				if (el.children?.length) {
					children = setLinks(el.children);
				}
				if (el.url) {
					return {
						...el,
						children,
						label: <Link to={el.url}>{el.label}</Link>,
					};
				} else {
					return {
						...el,
						children,
					};
				}
			})
			: [];
	};

	const nav = user ? setLinks(getNavigate(user)) : [];

	useEffect(() => {
		if (pathname !== "/" && !isLoading) {
			toggleLoading();
		}
	}, [pathname]);

	const routesLocal = user ? getRoutes(user) : [];

	const getSelectedNav = () => {
		let localNav = nav;
		let selectedKey;
		for (let i = 0; i < localNav.length; i++) {
			if (localNav[i].children?.length) {
				localNav = [...localNav, ...localNav[i].children];
			}
			if (localNav[i].url === pathname) {
				selectedKey = localNav[i].key;
				break;
			}
		}

		return [selectedKey];
	};

	const toggleLoading = () => setIsLoading((prevState) => !prevState);

	const toggleIsOpenDrawer = () => setIsOpenDrawer(prevState => !prevState);

	const toggleIsLoadingChecked = (type) => setIsLoadingChecked(prev => ({
		...prev,
		[type]: !prev[type],
	}))

	const siderStyle = {
		overflow: "auto",
		height: "100vh",
		position: "fixed",
		insetInlineStart: 0,
		top: 0,
		bottom: 0,
		scrollbarWidth: "thin",
		scrollbarColor: "unset",
	};

	const layoutStyle = {
		transitionProperty: "margin-inline-start",
		transitionDuration: ".3s",
	};

	const logoStyles = {
		width: "188px",
		display: "flex",
		justifyContent: "center",
		transitionProperty: "width",
		transitionDuration: ".2s",
		paddingTop: "7px",
		position: "fixed",
		overflow: "hidden",
		top: 0,
		left: 0,
		zIndex: 100,
		background: colorBgContainer,
	};

	const fioStyles = {
		minHeight: "50px",
		height: "50px",
		transitionProperty: "width",
		transitionDuration: ".2s",
		width: "188px",
		position: "fixed",
		bottom: 0,
		left: 0,
		background: colorBgContainer,
	};

	const setChecked = async (checked, type) => {
		toggleIsLoadingChecked(type)
		await api("user/update_self", {
			method: "POST",
			body: {
				[type]: checked,
			},
		});
		setUser({
			...user,
			[type]: checked
		})
		toggleIsLoadingChecked(type)
	}

	return (
		<Layout style={{height: "100vh"}}>
			<Sider trigger={null} style={{background: colorBgContainer, ...siderStyle}}>
				<div style={{position: "relative", width: "100%"}}>
					<div style={logoStyles}>
						<img src="/logo.svg" alt="image"/>
					</div>
					<Menu mode="inline" selectedKeys={getSelectedNav()} items={nav} style={{margin: "55px 0"}}/>
					<div style={fioStyles}>
						<Typography.Text ellipsis style={{cursor: "pointer", height: "100%", paddingLeft: "28px"}}
														 onClick={toggleIsOpenDrawer}>
							<UserOutlined/> Профиль
						</Typography.Text>
					</div>
				</div>
			</Sider>
			<Layout style={{display: "flex", flexDirection: "column", marginInlineStart: 200, ...layoutStyle}}>
				<Content
					style={{
						margin: 10,
						padding: 10,
						background: pathname === "/my-bill" || pathname === "/bills-for-payment" ? "#f5f5f5" : colorBgContainer,
						borderRadius: borderRadiusLG,
						flex: 1,
						overflowY: "auto",
					}}
				>
					<Routes>
						{routesLocal &&
							routesLocal.map((route, idx) => {
								return route.component ? (
									<Route
										key={idx}
										path={route.path}
										exact={route.exact}
										name={route.name}
										element={
											<Spin spinning={isLoading}>
												<Typography.Title level={3} style={{marginTop: 0}}>
													{route.name}
												</Typography.Title>
												<route.component toggleLoading={toggleLoading} isLoading={isLoading}/>
											</Spin>
										}
									/>
								) : null;
							})}
					</Routes>
				</Content>
			</Layout>
			<Drawer title={<Title level={3} style={{margin: 0}}>Профиль</Title>}
							placement={'left'}
							styles={{body: {padding: '0 24px'}}}
							onClose={toggleIsOpenDrawer}
							open={isOpenDrawer}>
				<Space direction={"vertical"}>
					<Row>
						<Col span={24}><Title style={{margin: '0.5em 0'}} level={5}>{user?.fullName || ''}</Title></Col>
						<Col span={24}><Button onClick={() => logout()} danger={true} variant="outlined">Выйти из
							профиля</Button></Col>
						<Col span={24}><Title level={4}>Настройки уведомлений</Title></Col>
						<Col span={19}>Уведомления "Мои счета"</Col>
						<Col span={4} offset={1}>
							<Switch loading={isLoadingChecked.notificationMyBill}
											checked={user?.notificationMyBill}
											onChange={checked => setChecked(checked, 'notificationMyBill')}/>
						</Col>
					</Row>
					<Row>
						<Col span={19}>Уведомления "Счета на оплату"</Col>
						<Col span={4} offset={1}>
							<Switch loading={isLoadingChecked.notificationBillConfirm}
											checked={user?.notificationBillConfirm}
											onChange={checked => setChecked(checked, 'notificationBillConfirm')}/>
						</Col>
					</Row>
				</Space>
			</Drawer>
		</Layout>
	);
};

export default DefaultLayout;
